import {useContext, useEffect, useMemo} from 'react';

import PageContext from '@/Providers/PageContextProvider/PageContext';

import usePreview from './use-preview';
import useSignInExperience from './use-sign-in-experience';
import {useSearchParams} from "react-router-dom";
import {Theme} from "@logto/schemas";

type Props = {
  children: React.ReactElement;
};

const SettingsProvider = ({ children }: Props) => {
  const { isPreview, experienceSettings, theme, setTheme } = useContext(PageContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchTheme = searchParams.get('theme');
    if (searchTheme) {
      if (searchTheme === 'dark' && theme !== Theme.Dark) {
        setTheme(Theme.Dark);
      } else if (searchTheme === 'light' && theme !== Theme.Light) {
        setTheme(Theme.Light);
      }
    }
  }, [searchParams, setTheme, theme]);

  const usePageLoad = useMemo(() => (isPreview ? usePreview : useSignInExperience), [isPreview]);

  usePageLoad();

  return experienceSettings ? children : null;
};

export default SettingsProvider;
