import type { Nullable } from '@silverhand/essentials';
import classNames from 'classnames';
import icons from 'country-flag-icons/react/3x2';
import type { ForwardedRef } from 'react';
import { useState, useMemo, forwardRef } from 'react';

import DownArrowIcon from '@/assets/icons/arrow-down.svg';
import { onKeyDownHandler } from '@/utils/a11y';
import { getCountryList, getDefaultCountryCallingCode } from '@/utils/country-code';

import CountryCodeDropdown from './CountryCodeDropdown';
import * as styles from './index.module.scss';

type Props = {
  className?: string;
  value?: string;
  inputRef?: Nullable<HTMLInputElement>;
  isVisible?: boolean;
  onChange?: (value: string) => void;
};

const CountryCodeSelector = (
  { className, value, inputRef, isVisible = true, onChange }: Props,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const countryList = useMemo(getCountryList, []);
  const defaultCountCode = useMemo(getDefaultCountryCallingCode, []);

  const showDropDown = () => {
    setIsDropdownOpen(true);
  };

  const hideDropDown = () => {
    setIsDropdownOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const countryCode = value || defaultCountCode;

  const getFlag = (countryNumber: string) => {
    const code = countryList.find((country) => country.countryCallingCode === countryNumber)
      ?.countryCode;
    if (!code) {
      return null;
    }
    // eslint-disable-next-line no-prototype-builtins
    const isFlag = icons.hasOwnProperty(code);
    return isFlag ? icons[code] : null;
  };

  const Flag = getFlag(countryCode);

  return (
    <div
      ref={ref}
      className={classNames(styles.countryCodeSelector, className)}
      role="button"
      tabIndex={isVisible ? 0 : -1}
      onClick={showDropDown}
      onKeyDown={onKeyDownHandler({
        Enter: showDropDown,
      })}
    >
      {Flag && (
        <Flag
          width={35}
          style={{
            width: '35px',
            height: '24px',
            borderRadius: '4px',
            marginRight: '4px',
            marginLeft: 'unset',
          }}
        />
      )}
      <span>{`+${countryCode}`}</span>
      <DownArrowIcon />
      <CountryCodeDropdown
        inputRef={inputRef}
        isOpen={isDropdownOpen}
        countryCode={countryCode}
        countryList={countryList}
        onClose={hideDropDown}
        onChange={onChange}
      />
    </div>
  );
};

export default forwardRef(CountryCodeSelector);
