@use 'src/scss/underscore' as _;

.countryCodeSelector {
  font: var(--font-label-1);
  color: var(--color-type-primary);
  border: _.border('transparent');
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  background: none;
  position: relative;
  height: 100%;
  padding-right: _.unit(1);
  padding-left: _.unit(4);
  @include _.flex-row;
  overflow: hidden;
  white-space: nowrap;

  &:focus-visible {
    border: _.border(var(--color-brand-default));
  }

  > svg {
    flex-shrink: 0;
    color: var(--color-type-secondary);
    margin-left: _.unit(1);
    width: 16px;
    height: 16px;
  }
}

:global(body.desktop) {
  .countryCodeSelector {
    font: var(--font-body-2);

    > svg {
      margin-left: _.unit(2);
      width: 20px;
      height: 20px;
    }
  }
}
