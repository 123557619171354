// Import { Theme } from '@logto/schemas';
import { /* useEffect, */ useContext } from 'react';

import PageContext from '@/Providers/PageContextProvider/PageContext';

// Const darkThemeWatchMedia = window.matchMedia('(prefers-color-scheme: dark)');
// const getThemeBySystemConfiguration = (): Theme =>
//   darkThemeWatchMedia.matches ? Theme.Dark : Theme.Light;

export default function useTheme() {
  useContext(PageContext);

  // UseEffect(() => {
  //   if (!experienceSettings?.color.isDarkModeEnabled) {
  //     return;
  //   }
  //
  //   const changeTheme = () => {
  //     setTheme(getThemeBySystemConfiguration());
  //   };
  //
  //   darkThemeWatchMedia.addEventListener('change', changeTheme);
  //
  //   return () => {
  //     darkThemeWatchMedia.removeEventListener('change', changeTheme);
  //   };
  // }, [experienceSettings, isPreview, setTheme]);
}
