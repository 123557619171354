import Lottie from 'lottie-react';

import yodaLoader from './yoda_loader.json';

type Props = {
  className?: string;
};

const LoadingIcon = ({ className }: Props) => (
  // <LoadingSvg className={classNames(styles.loadingIcon, className)} />
  // <div className={className}>
  <Lottie loop animationData={yodaLoader} />
  // </div>
);

export default LoadingIcon;
